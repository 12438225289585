// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-item-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-team-member-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/TeamMemberTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-team-member-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bridal-jsx": () => import("./../../../src/pages/bridal.jsx" /* webpackChunkName: "component---src-pages-bridal-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-giving-back-jsx": () => import("./../../../src/pages/giving-back.jsx" /* webpackChunkName: "component---src-pages-giving-back-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-sk-aesthetics-jsx": () => import("./../../../src/pages/sk-aesthetics.jsx" /* webpackChunkName: "component---src-pages-sk-aesthetics-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-update-my-appointment-jsx": () => import("./../../../src/pages/update-my-appointment.jsx" /* webpackChunkName: "component---src-pages-update-my-appointment-jsx" */)
}

